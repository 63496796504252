import React from "react";

const Header = ({ scrollRef }) => {
    return (
        <div
            className="w-full h-14 sticky top-0 z-50 bg-inherit backdrop-blur-md"
            ref={scrollRef}
        >
            <div className="w-full h-full text-zillow flex flex-row items-center justify-between px-4 font-extrabold">
                <a href="/">
                    <i className="fa-solid fa-terminal p-2"></i>
                </a>
                <div className="flex flex-row gap-2">
                    <a href="#about">About</a>
                    <a href="#experience">Experience</a>
                    <a href="#tools">Tools</a>
                </div>
            </div>
        </div>
    );
};

export default Header;
