import React from "react";
import Socials from "./Socials";

const Hero = () => {
    return (
        <>
            <section
                id="home"
                className="max-w-5xl w-full h-screen mx-auto py-4 flex items-center justify-center font-bold bg-inherit flex-col gap-4"
            >
                <div className="flex flex-col items-center justify-center text-center gap-4 md:gap-2 text-3xl lg:text-6xl">
                    <h1 className="flex flex-row text-5xl py-2">
                        Hi, I'm <p className="text-zillow pl-3">Steve</p>
                    </h1>
                    <p className="text-3xl md:text-5xl">
                        A Front-End Software Engineer
                    </p>
                </div>
                <div className="font-normal text-gray-400 text-xl text-center px-6">
                    I specialize in bringing back-end data to life
                </div>
                <Socials />
            </section>
        </>
    );
};

export default Hero;
