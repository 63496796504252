import "./App.css";
import React from "react";
import Hero from "./components/Hero";
import Info from "./components/Info";
import Header from "./components/Header";
import About from "./components/About";
import Experience from "./components/Experience";

function App() {
    return (
        <div className="App font-raleway">
            <Header />
            <Hero />
            <About />
            <Experience />
            <Info />
        </div>
    );
}

export default App;
