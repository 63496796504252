import React from "react";

const Info = () => {
    const toolObj = {
        javascript: {
            logo: "/images/javascript.png",
            title: "JavaScript",
        },
        typescript: {
            logo: "/images/typescript.png",
            title: "TypeScript",
        },
        react: {
            logo: "/images/react.png",
            title: "React",
        },
        next: {
            logo: "/images/next.png",
            title: "NextJS",
        },
        redux: {
            logo: "/images/redux.png",
            title: "Redux",
        },
        saga: {
            logo: "/images/saga.png",
            title: "Saga",
        },
        tailwind: {
            logo: "/images/tailwind.png",
            title: "Tailwind",
        },
        mui: {
            logo: "/images/mui.png",
            title: "Material UI",
        },
        rails: {
            logo: "/images/rails.png",
            title: "Rails",
        },
        css: {
            logo: "/images/css.png",
            title: "CSS",
        },
    };

    return (
        <section
            id="tools"
            className="flex flex-col items-center justify-center bg-inherit gap-8 px-4 font-raleway w-full py-8"
        >
            <p className="text-zillow font-bold font-raleway text-3xl">Tools</p>
            <div className="w-full h-full grid grid-cols-2 md:grid-cols-5 gap-8">
                {Object.keys(toolObj).map((tool) => {
                    return (
                        <div className="flex flex-col gap-2 items-center justify-center p-2 rounded-2xl shadow-md shadow-slate-350 hover:shadow-lg max-w-sm m-auto w-36 md:w-44">
                            <img
                                src={toolObj[tool].logo}
                                alt="oops"
                                className="h-16 w-18"
                            />
                            <div className="font-bold">
                                {toolObj[tool].title}
                            </div>
                        </div>
                    );
                })}
            </div>
        </section>
    );
};

export default Info;
