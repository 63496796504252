import React from "react";

const Experience = () => {
    return (
        <section
            className="flex flex-col items-center justify-center bg-inherit px-4 font-raleway w-full py-8 h-screen w-auto"
            id="experience"
        >
            <div className="flex flex-col gap-8 max-w-3xl items-center w-full">
                <h1 className="text-zillow font-bold font-raleway text-3xl text-center">
                    Experience
                </h1>
                <div className="flex flex-col gap-2 text-left w-full">
                    <a
                        href="https://www.slalom.com/"
                        className="font-bold text-xl"
                    >
                        Slalom &#40;current&#41;
                    </a>
                    <div className="px-2 flex flex-col gap-2">
                        <p>
                            Part of a specialized team that focuses on building
                            modern websites and applications for our clients.
                            The tech stack is predominantly NextJS and CMS
                            integration.
                        </p>
                        <p>
                            At Slalom, I also co-lead an employee resource group
                            that focuses around our employee's physical,
                            emotional, social, and financial well-being.
                        </p>
                    </div>
                </div>
                <div className="flex flex-col gap-2 text-left w-full">
                    <a
                        href="https://buildingintelligence.com/"
                        className="font-bold text-xl"
                    >
                        Building Intelligence
                    </a>
                    <div className="px-2 flex flex-col gap-2">
                        <p>
                            Responsible for feature development and overall
                            maintenance of a Rails monolith application. Helped
                            transform legacy front-end to a modern, React based
                            user interface.
                        </p>
                        <p>
                            This was my first role as an engineer, and at what
                            one might consider an established start-up. We
                            shipped quickly and we shipped daily - it was a
                            blast.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Experience;
