import React from "react";

const About = () => {
    const murraysBirthday = new Date(2021, 3, 16).getFullYear();
    const today = new Date().getFullYear();
    return (
        <section
            id="about"
            className="flex flex-col items-center justify-center bg-inherit px-4 font-raleway w-full py-8 h-screen w-auto"
        >
            <div className="flex flex-col gap-8 max-w-3xl items-center w-full">
                <h1 className="text-zillow font-bold font-raleway text-3xl text-center">
                    About Me
                </h1>
                <div className="flex flex-col md:flex-row gap-4 md:justify-center items-center">
                    <img
                        src="/images/IMG_0940.jpeg"
                        alt="oops"
                        className="rounded-lg h-72 w-72 shadow-md shadow-slate-350"
                    />
                    <div className="text-left w-full flex flex-col gap-4">
                        <p>
                            I'm a software engineer focused on building modern,
                            responsive user interfaces. My experience is
                            primarily rooted in JavaScript &#40;client and
                            server side&#41;, React, Redux, and Rails but I
                            enjoy &#40;and try&#41; to seek out as much
                            opportunity to learn and tinker with new languages
                            and technologies.
                        </p>
                        <p>
                            Outside of all that, I live in Denver with my fiancé
                            and {`${today - murraysBirthday}`} year old Shetland
                            Sheepdog, Murray. I try to spend my free time being
                            active, whether that's in the gym, snowboarding, or
                            hiking.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
