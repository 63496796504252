import React from "react";

const Socials = () => {
    return (
        <section className="flex flex-row gap-10 md:gap-12">
            <a href="https://www.linkedin.com/in/steveneross1/">
                <div className="p-6 rounded-2xl shadow-lg shadow-slate-350 hover:shadow-md cursor-pointer">
                    <i className="fa-brands fa-linkedin"></i>
                </div>
            </a>
            <a href="https://github.com/steveneross94">
                <div className="p-6 rounded-2xl shadow-lg shadow-slate-350 hover:shadow-md cursor-pointer">
                    <i className="fa-brands fa-square-github"></i>
                </div>
            </a>
            <a href="https://twitter.com/Senorr_Ross">
                <div className="p-6 rounded-2xl shadow-lg shadow-slate-350 hover:shadow-md cursor-pointer">
                    <i className="fa-brands fa-square-twitter"></i>
                </div>
            </a>
        </section>
    );
};

export default Socials;
